.header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    z-index: 1000;    

    .headerContent{
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
    }

    .menu{
        color: white;
        right: 0;
        width: 79.333%;
        transition: 0.2s all ease-in-out;
        display: flex;
        justify-content: flex-end;

        .content{
            background-color: $primaryColor;
            position: relative;
        }
        a{
            color: white;
            text-decoration: none;
            display: block;
        }
        .closeIcon, .menuHeader, .divider{
            display: none;
        }
        nav{
            padding: 10px;
            justify-content: flex-end;
            display: flex;
            // width: 100%;

            a{
                padding: 8px 24px;
                font-size: 18px;
                font-family: "Signika", Sans-serif;
                font-weight: 300;
            }
        }
    }
    .buttonNav{
        display: inline-block;
        font-size: 18px;
        color: white;
        text-align: center;
        background-color: $green;
        border-radius: 6px;
        text-decoration: none;
        font-family: "Signika", Sans-serif;        
    }

}
.logo{
    width: 20.332%;
    float: left;
    a{
        // padding: 10px;
        display: block;

        img{
            width: 100%;
            min-width: 175px;
        }
    }
}
.hamburguer-icon{
    display: none;
}
.logo{
    display: block;
}
.page{
    margin-top: 67px;
}
.hero{
    overflow: hidden;
    height: 100%;

    .content{
        padding: 200px 0 200px;
        min-height: 700px;
        width: 100%;
        max-width: 1200px;
        flex-wrap: wrap;
        margin: 0 auto;

        h1{
            width: 48%;
            font-size: 46px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        p{
            width: 48%;
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
}
.block{
    padding: 20px;
    margin-top: 20px;
    color: $primaryColor;

    .blockContent{
        overflow: hidden;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 10px;    }
    .imageBlock{
        float: right;
        width: 50%;
        padding: 0 3%;
        img{
            width: 100%;
            text-align: center;
            display: block;
        }
    }
    .left{
        float: left;
        width: 50%;
    }
    h2{
        font-size: 48px;
        margin-bottom: 10px;
        padding: 10px;
        span{
            font-weight: 300;
        }
    }
    p{
        padding: 10px;
        font-size: 20px;
        margin-bottom: 20px;
    }    
}
.benefits{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .content{
        padding: 30px;
        overflow: hidden;
    }
    h3{
        font-family: "Signika", Sans-serif;
        font-size: 32px;
        font-weight: 500;
        color: $primaryColor;
    }
    .benefit{
        margin-bottom: 20px;
        padding: 10px;
        width: 33.33%;
        float: left;
        overflow: hidden;

        h4{
            font-weight: 300;
            width: 80%;
            float: right;
            text-align: left;
            font-size: 20px;
        }
        p{
            font-size: 16px;
            width: 80%;
            padding-right: 2%;
            float: right;
        }
    }
    .icon{
        width: 20%;
        float: left;
        padding-right: 4%;
    }
    .benefitsButton{
        font-family: "Signika", Sans-serif;
        font-size: 24px;
        font-weight: 100;
        background-color: $green;
        border-width: 1px;
        border-radius: 6px;
        color: white;
        text-decoration: none;
        display: block;
        text-align: center;
        line-height: 2;
        padding: 0px;
        float: left;
        width: 33.33%;
    }
}
.services{
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;

    .service{
        overflow: hidden;
        margin-bottom: 50px;

        .bgImage{
            min-height: 774px;
            width: 100%;
            border-radius: 10px;
        }
        h2{
            text-align: left;
            font-family: "Signika", Sans-serif;
            font-size: 48px;

            span{
                font-weight: 300;
            }
        }
        .right{

            p{
                font-family: "Roboto", Sans-serif;
                font-size: 18px;
                text-align: left;
                padding-right: 30px;
            }
            .benefits{
                width: 90% !important;
                margin: 0;
            }
            .benefit{
                margin-bottom: 20px;
                padding: 10px 0;
                width: 89%;
                float: left;
                overflow: hidden;
        
                h4{
                    font-weight: 300;
                    width: 80%;
                    float: right;
                    text-align: left;
                    font-size: 20px;
                }
                p{
                    font-size: 16px;
                    width: 80%;
                    padding-right: 2%;
                    float: right;
                }
            }            
            .benefitsButton{
                width: 100% !important;
            }
        }
    }
    .service:nth-child(odd){
        .left{
            float: right;
            width: 50%;
        }
        .right{
            float: left;
            width: 50%;
        }
    }
    .service:nth-child(even){
        .left{
            float: left;
            width: 50%;
        }
        .right{
            float: right;
            width: 46%;
        }
    }    
}

.footerBlock{
    padding: 20px 10px 50px;

    .content{
        background-color: $primaryColor;
        padding: 60px 70px;
        border-radius: 10px;
        color: white;   
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
        box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);

        .kicker{
            margin-bottom: 10px;
            p{
                font-family: "Signika", Sans-serif !important;
                font-size: 18px;    
            }
        }
        h3{
            font-family: "Signika", Sans-serif;
            font-size: 32px;
            margin-bottom: 20px;
        }
        p{
            font-family: 'Roboto', Sans-serif;
            margin-bottom: 20px;
            font-size: 17px;
            width: 44%;
        }
    }
    .button{
        display: inline-block;
        padding: 10px 20px;
        color: white;
        text-align: center;
        background-color: $green;
        border-radius: 10px;
        text-decoration: none;
        font-family: "Signika", Sans-serif;
    }
}
.footer{
    padding: 20px 0;
    .content{
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
        .copy{
            font-size: 16px;
        }
        a{
            padding: 0 10px;
            font-size: 16px;
        }
    }
}
.notFound{
    
    .content{
        width: 100%;
        padding-top: 50px;
        margin: 0 auto;
        max-width: 1200px;    
    }
}