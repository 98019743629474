@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

$primaryColor: #001f54;
$green: #17C65D;

html, body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    scroll-behavior: smooth;
    color: $primaryColor;
    font-size: 90%;
    -webkit-text-size-adjust: 100%;
}
p{
    font-family: 'Roboto', Sans-serif;
}
img{
    width: 100%;
    display: block;
}
.header{
    background-color: $primaryColor;
    width: 100%;
}

.hero{
    position: relative;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    background-color: $primaryColor;

    .content{
        font-family: "Signika", Sans-serif;
        color: white;
        position: relative;

        p{
            font-family: 'Roboto', Sans-serif;
        }
    }
    .bg-hero{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
    }
    .heroButton{
        font-family: "Signika", Sans-serif;
        font-size: 16px;
        font-weight: 300;
        background-color: #17C65D00;
        border: 1px solid white;
        border-radius: 6px 6px 6px 6px;
        display: inline-block;
        padding: 12px 24px;
        color: #fff;
        text-decoration: none;
        text-align: center;
        transition: all .3s;
        font-size: 16px;
        line-height: 1;
    }
    .heroButton:visited {
        color: #fff;
    }
}
.block{
    h2{
        font-family: "Signika", Sans-serif;
    }
    p{
        font-family: 'Roboto', Sans-serif;
    }
}
.benefits{
    padding: 10px;
    .content{
        box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 3%);
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        // margin-top: 20px;
        margin-bottom: 60px;
        padding: 20px;    
        border-radius: 12px;
    }
    h3{
        margin-bottom: 10px;
    }
    .icon{
        width: 60px;
        margin: 0 auto;
    }
    h4{
        color: $green;
        text-align: center;
    }

}
.footer{
    background-color: $primaryColor;
    color: white;
    overflow: hidden;
    
    .copy, .terms{
        width: 50%;
        padding: 10px;
    }
    .copy{
        float: left;
    }
    .terms{
        float: right;
        text-align: right;
    }
    a{
        color: white;
        text-decoration: none;
        clear: both;
        display: inline-block;
    }
}

.whatsappIcon{
    background-color: $green;
    border-radius: 100%;
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: white;
    display: block;
    aspect-ratio: 1;
    height: 58px;
    font-size: 40px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    a{
        align-content: center;
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
    }
    a:visited{
        color: white;
    }
    
}
@media all and (max-width: 768px) {
    @import "mobile.scss";
}
@media all and (min-width: 769px) {
    @import "desktop.scss";
}

.inner{
    width: 100%;
    max-width: 1200px;
    padding: 40px 0;
    margin: 0 auto;
}
.terms, .policy{
    padding: 10px;
    p, span{
        font-family: 'Montserrat', sans-serif;
        color: #7A7A7A;
        font-weight: 400;
        font-size: 1.1rem;
    }
    h1, h2, h3{
        margin-bottom: 20px;
    }
    h3{
        color: #7A7A7A;
    }
}
.page, html, body{
    transition: 0.4s all ease-out;
}
.footerBlock{
    .content{
        p, span{
            color: white;
        }
    }
}
.fadeInDown{
    animation-name: fadeInDown;
    animation-duration: 1.25s;
}
.fadeInUp{
    animation-name: fadeInUp;
    animation-duration: 1.25s;
}
.fadeIn {
    animation-name: fadeIn;
    animation-duration: 1.25s;
}
.zoomIn {
    animation-name: zoomIn;
    animation-duration: 1.25s;
}
@keyframes fadeIn{
    from{
        opacity:0
    }
    to{
        opacity:1
    }
}
@keyframes fadeInDown{
    from{
        opacity:0;
        transform:translate3d(0,-100%,0)
    }
    to{
        opacity:1;
        transform:none
    }
}
@keyframes fadeInUp{
    from{
        opacity:0;
        transform:translate3d(0,100%,0)
    }
    to{
        opacity:1;
        transform:none
    }
}
@keyframes zoomIn{
    from{
        opacity:0;
        transform:scale3d(.3,.3,.3)
    }
    50%{
        opacity:1
    }
}
.no-visible{
    opacity: 0;
}
.footerBlock{
    position: relative;

    .bgGray{
        background-color: #E7E7E7;
        height: 75%;
        position: absolute;
        bottom: 0;
        z-index: -1;
        width: 100%;
        left: 0;
    }
}
