.header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    z-index: 1000;    
    height: 64px;

    .menu{
        position: fixed;
        top: 0;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.9);
        color: white;
        right: 0;
        width: 100%;
        transition: 0.2s all ease-in-out;

        .content{
            background-color: $primaryColor;
            width: 90%;
            margin-left: 10%;
            height: 100%;
            padding-top: 20px;
            position: relative;
        }
        a{
            color: white;
            text-decoration: none;
            display: block;
        }
        .closeIcon{
            margin: 0 auto;
            position: absolute;
            right: 15px;
            top: 15px;
            *{
                float: right;
            }
        }
        .menuHeader{
            width: 70%;
            margin: 50px auto 20px;
        }
        .divider{
            margin: 40px auto;
            span{
                width: 96%;
                margin: 0 auto;
                border-top: 1px solid #FFFFFF45;
                display: flex;
            }
        }
        nav{
            a{
                padding: 10px 20px;
                font-size: 20px;
                font-family: "Signika", Sans-serif;
                font-weight: 300;
            }
        }
    }
    .menu-inactive{
        right: -100%;
    }
    .buttonNav{
        display: none !important;
    }
}
.logo{
    width: 50%;
    float: left;
    a{
        // padding: 10px;
        display: block;

        img{
            width: 100%;
            min-width: 175px;
        }
    }
}
.hamburguer-icon{
    width: 50%;
    float: right;
    position: relative;
    font-size: 1.8em;
    padding-right: 10px;

    .icon{
        color: white;
        float: right;
        padding-top: 8px;
        padding: 10px;
    }
}
.logo, .hamburguer-icon{
    display: block;
}
.page{
    margin-top: 54px;
}
.hero{
    overflow: hidden;
    height: 100%;
    .content{
        padding: 100px 20px;
        min-height: 400px;
        flex-wrap: wrap;
        h1{
            font-size: 36px;
            margin-bottom: 20px;
        }
        p{
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
}
.block{
    padding: 20px;
    margin-top: 20px;
    color: $primaryColor;
    h2{
        font-size: 32px;
        margin-bottom: 20px;
        span{
            font-weight: 300;
        }
    }
    p{
        font-size: 18px;
        margin-bottom: 20px;
    }    
}
.benefits{
    h3{
        font-family: "Signika", Sans-serif;
        font-size: 32px;
        font-weight: 600;
        text-align: center;
        color: $primaryColor;
    }
    .benefit{
        margin-bottom: 20px;
        text-align: center;
        padding: 10px;
        h4{
            font-weight: 300;
        }
        p{
            font-size: 16px;
        }
    }
    .icon{
        margin-bottom: 15px;
    }
    .benefitsButton{
        font-family: "Signika", Sans-serif;
        font-size: 24px;
        font-weight: 100;
        background-color: $green;
        border-style: solid;
        border-width: 1px;
        border-radius: 6px;
        color: white;
        text-decoration: none;
        width: 100%;
        display: block;
        text-align: center;
        line-height: 2.5;
        
    }    
}
.services{
    .service{
        .bgImage{
            min-height: 220px;
            width: 100%;
            border-radius: 10px;
        }
        h2{
            text-align: center;
            font-family: "Signika", Sans-serif;
            font-size: 32px;

            span{
                font-weight: 300;
            }
        }
        .right{
            padding: 10px;

            p{
                font-family: "Roboto", Sans-serif;
                font-size: 16px;
                text-align: center;
            }
        }
    }
}
.footerBlock{
    padding: 20px 10px 50px;
    .content{
        background-color: $primaryColor;
        padding: 60px 30px;
        border-radius: 10px;
        color: white;   

        .kicker{
            margin-bottom: 10px;
            p{
                font-family: "Signika", Sans-serif !important;
                font-size: 16px;    
            }
        }
        h3{
            font-family: "Signika", Sans-serif;
            font-size: 30px;
            margin-bottom: 20px;
        }
        p{
            font-family: 'Roboto', Sans-serif;
            margin-bottom: 20px;
            font-size: 18px;
        }
    }
    .button{
        display: inline-block;
        padding: 10px 20px;
        color: white;
        text-align: center;
        background-color: $green;
        border-radius: 10px;
        text-decoration: none;
        font-family: "Signika", Sans-serif;
    }
}
.chevrondown{
    display: none;
}
.notFound{
    
    .content{
        width: 100%;
        padding: 50px 20px;
        margin: 0 auto;
        max-width: 1200px;    
    }
}